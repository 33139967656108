export const LOADER = "LOADER";

// for refresh Token
export const REFRESH_TOKEN_SUCCESS = "REFRESH_TOKEN_SUCCESS";
export const REFRESH_TOKEN_FAIL = "REFRESH_TOKEN_FAIL";

// for get dashboard Api
export const GET_DASHBOARD_DATA_INIT = "GET_DASHBOARD_DATA_INIT";
export const GET_DASHBOARD_DATA_SUCCESS = "GET_DASHBOARD_DATA_SUCCESS";
export const GET_DASHBOARD_DATA_FAIL = "GET_DASHBOARD_DATA_FAIL";

// For Explore Category Api
export const EXPLORE_CATEGORY_INIT = "EXPLORE_CATEGORY_INIT";
export const EXPLORE_CATEGORY_SUCCESS = "EXPLORE_CATEGORY_SUCCESS";
export const EXPLORE_CATEGORY_FAIL = "EXPLORE_CATEGORY_FAIL";

// For Favorite By User APi
export const FAVORITE_BY_USER_INIT = "FAVORITE_BY_USER_INIT";
export const FAVORITE_BY_USER_SUCCESS = "FAVORITE_BY_USER_SUCCESS";
export const FAVORITE_BY_USER_FAIL = "FAVORITE_BY_USER_FAIL";
export const CLEARE_FAVORITE_BY_USER_SUCCESS =
  "CLEARE_FAVORITE_BY_USER_SUCCESS";
export const UPDATE_FAVORITE_BY_USER_SUCCESS =
  "UPDATE_FAVORITE_BY_USER_SUCCESS";
//For get All Categories For Users Api
export const GET_ALL_CATEGORIES_USER_INIT = "GET_ALL_CATEGORIES_USER_INIT";
export const GET_ALL_CATEGORIES_USER_SUCCESS =
  "GET_ALL_CATEGORIES_USER_SUCCESS";
export const GET_ALL_CATEGORIES_USER_FAIL = "GET_ALL_CATEGORIES_USER_FAIL";
export const CLEARE_ALL_CATEGORIES_USER_SUCCESS = "CLEARE_ALL_CATEGORIES_USER_SUCCESS";

//For get All Sub Categories For User Api
export const GET_ALL_SUB_CATEGORIES_USER_INIT =
  "GET_ALL_SUB_CATEGORIES_USER_INIT";
export const GET_ALL_SUB_CATEGORIES_USER_SUCCESS =
  "GET_ALL_SUB_CATEGORIES_USER_SUCCESS";
export const GET_ALL_SUB_CATEGORIES_USER_FAIL =
  "GET_ALL_SUB_CATEGORIES_USER_FAIL";

// For update Favorites User Api
export const UPDATE_FAVORITE_INIT = "UPDATE_FAVORITE_INIT";
export const UPDATE_FAVORITE_SUCCESS = "UPDATE_FAVORITE_SUCCESS";
export const UPDATE_FAVORITE_FAIL = "UPDATE_FAVORITE_FAIL";

// For Block and Unblock Category User Api
export const BLOCK_UN_BLOCK_INIT = "BLOCK_UN_BLOCK_INIT";
export const BLOCK_UN_BLOCK_SUCCESS = "BLOCK_UN_BLOCK_SUCCESS";
export const BLOCK_UN_BLOCK_FAIL = "BLOCK_UN_BLOCK_FAIL";
export const CLEARE_BLOCK_UN_BLOCK_SUCCESS = "CLEARE_BLOCK_UN_BLOCK_SUCCESS";

// For GetAllSubCategoriesForUserByParentCategoryID Api
export const GET_ALL_SUB_CATEGORY_PARENT_INIT =
  "GET_ALL_SUB_CATEGORY_PARENT_INIT";
export const GET_ALL_SUB_CATEGORY_PARENT_SUCCESS =
  "GET_ALL_SUB_CATEGORY_PARENT_SUCCESS";
export const GET_ALL_SUB_CATEGORY_PARENT_FAIL =
  "GET_ALL_SUB_CATEGORY_PARENT_FAIL";
  export const CLEARE_ALL_SUB_CATEGORY_PARENT_SUCCESS =
  "CLEARE_ALL_SUB_CATEGORY_PARENT_SUCCESS";
  
// For LikeUnlikeBusiness Api
export const LIKE_UNLIKE_BUSINESS_INIT = "LIKE_UNLIKE_BUSINESS_INIT";
export const LIKE_UNLIKE_BUSINESS_SUCCESS = "LIKE_UNLIKE_BUSINESS_SUCCESS";
export const LIKE_UNLIKE_BUSINESS_FAIL = "LIKE_UNLIKE_BUSINESS_FAIL";
export const CLEARE_LIKE_UNLIKE_BUSINESS_SUCCESS =
  "CLEARE_LIKE_UNLIKE_BUSINESS_SUCCESS";

//For seacrhApi Blancspace
export const SEARCH_BLANCSPACE_INIT = "SEARCH_BLANCSPACE_INIT";
export const SEARCH_BLANCSPACE_SUCCESS = "SEARCH_BLANCSPACE_SUCCESS";
export const SEARCH_BLANCSPACE_FAIL = "SEARCH_BLANCSPACE_FAIL";
export const CLEARE_SEARCH_BLANCSPACE_SUCCESS = "CLEARE_SEARCH_BLANCSPACE_SUCCESS";

// for category filtering data in search
export const FILTER_DATA = "FILTER_DATA";

// for business details
export const BUSINESS_DETAIL_INIT = "BUSINESS_DETAIL_INIT";
export const BUSINESS_DETAIL_SUCCESS = "BUSINESS_DETAIL_SUCCESS";
export const BUSINESS_DETAIL_FAIL = "BUSINESS_DETAIL_FAIL";

// for hidden Inputs for latitude and Longitude
export const LONGITUTDE_LOCATION_DATA = "LONGITUTDE_LOCATION_DATA";

// for hidden Inputs for latitude and Latitude
export const LATITUDE_LOCATION_DATA = "LATITUDE_LOCATION_DATA";

// for LOADER CHECK
export const CATEGORY_ROUTE_LOADER = "CATEGORY_ROUTE_LOADER";